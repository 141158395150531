<template>
  <ul class="menu-nav">
    <template v-for="(row, index) in MenuList">
      <template v-if="lodash.isArray(row)">
        <li
          v-bind:key="index"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="{
            'menu-item-open menu-item-open-active': isChildActive(row),
          }"
        >
          <a href="#" class="menu-link menu-toggle">
            <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="
                  $assetURL('media/custom-svg/' + getGroupTitle(row) + '.svg')
                "
              />
              <!--end::Svg Icon-->
            </span>
            <span class="menu-text text-capitalize">{{
              getGroupTitle(row)
            }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link">
                  <span class="menu-text text-capitalize">{{
                    getGroupTitle(row)
                  }}</span>
                </span>
              </li>
              <template v-for="(rowd, indexd) in row">
                <router-link
                  v-bind:key="indexd"
                  v-bind:to="rowd.route"
                  v-bind:class="{
                    'menu-item-active': isRouteActive(rowd.menu),
                    'disable-link': disableLink(rowd.menu),
                  }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                  >
                    <a :href="href" class="menu-link" v-on:click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">{{ rowd.title }}</span>
                    </a>
                  </li>
                </router-link>
              </template>
            </ul>
          </div>
        </li>
      </template>
      <router-link
        v-else
        v-bind:key="index"
        v-bind:to="row.route"
        v-bind:class="{
          'menu-item-active': isRouteActive(row.menu),
          'disable-link': disableLink(row.menu),
        }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <v-tooltip
          right
          content-class="custom-right-tooltip menu-tooltip-hover hide-menu-tooltip-hover"
        >
          <template v-slot:activator="{ on, attrs }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-bind="attrs"
              v-on="on"
              v-bind:class="{
                'menu-item-active': isRouteActive(row.menu),
                'disable-link': disableLink(row.menu),
              }"
            >
              <a :href="href" class="menu-link" v-on:click="navigate">
                <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/' + row.icon + '.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <span class="menu-text">{{ getMenuTitle(row) }}</span>
              </a>
            </li>
          </template>
          <span class="menu-tooltip-hover-target">{{ getMenuTitle(row) }}</span>
        </v-tooltip>
      </router-link>
    </template>
  </ul>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import inflect from "i";

export default {
  name: "KTMenu",
  data() {
    return {
      MenuList: [],
    };
  },
  methods: {
    getMenuTitle(row) {
      if (!row) {
        return null;
      }

      if (row.menu != "dashboard" && row.menu != "calendar") {
        return inflect().pluralize(row.title);
      } else {
        return row.title;
      }
    },
    isChildActive(row) {
      let isActive = false;
      for (let i = 0; i < row.length; i++) {
        isActive = this.isRouteActive(row[i].menu);
        if (isActive) {
          return isActive;
        }
      }
      return isActive;
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isRouteActive(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return this.$route.meta.menuActive === match;
      }
      return false;
    },
    disableLink(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return (
          this.$route.meta.menuActive === match && this.$route.meta.isListing
        );
      }
      return false;
    },
    getGroupTitle(row) {
      for (let i = 0; i < row.length; i++) {
        if (row[i].group) {
          return row[i].group;
        }
      }
    },
    getMenuList() {
      const _this = this;
      try {
        const result = JwtService.menuList();
        const arrays = [];
        const objects = [];
        const topTwo = [];
        const lastOne = [];
        for (const menu in result) {
          if (_this.lodash.isArray(result[menu])) {
            arrays.push(result[menu]);
          } else if (
            _this.lodash.isObject(result[menu]) &&
            result[menu].order <= 1
          ) {
            topTwo.push(result[menu]);
          } else if (
            _this.lodash.isObject(result[menu]) &&
            result[menu].order >= 21
          ) {
            lastOne.push(result[menu]);
          } else if (_this.lodash.isObject(result[menu])) {
            objects.push(result[menu]);
          }
        }
        _this.MenuList = [...topTwo, ...arrays, ...objects, ...lastOne];
        if (_this.lodash.isEmpty(_this.MenuList)) {
          JwtService.destroyToken();
        }
      } catch (error) {
        _this.logError(error);
        JwtService.destroyToken();
      }
    },
  },
  mounted() {
    this.getMenuList();
  },
};
</script>
